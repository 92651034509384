import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout/';

export default class NotFoundPage extends React.Component {

    render () {
        return (
            <Layout title='404 Not Found'>
                <article>

                    <header>
                        <h1>404: Not Found</h1>
                    </header>

                    <aside>
                        <p><Link to='/'>← Return to the Homepage</Link></p>
                    </aside>

                    <section>
                        <p>The requested page could not be found.</p>
                    </section>

                </article>

            </Layout>
        );
    }

}
